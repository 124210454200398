<template>
  <div>
    <div
      show
      variant="light"
      class="
        alert alert-white alert-shadow
        fade
        show
        gutter-b
        justify-content-end
      "
    >
      <b-row>

      <div class="col-3">

          <b-col ><label :for="`date`">NCC dịch vụ</label></b-col>
          <b-col >
            <b-form-select
                v-model="service"
                :options="options.service"
                text-field="name"
                value-field="code"
            ></b-form-select>
          </b-col>

      </div>

        <div class="col-3">
          <b-col ><label :for="`date`">Loại dịch vụ</label></b-col>
          <b-col >
            <b-form-select
                v-model="provider"
                :options="options.provider"
                text-field="name"
                value-field="code"
            ></b-form-select>
          </b-col>

      </div>
        <div class="col-6">
          <b-col ><label :for="`date`">Dịch vụ</label></b-col>
          <b-col >
            <multiselect
                v-model="list_service"
                :options="options.list_service"
                :multiple="true"
                label="name"
                track-by="id"
            ></multiselect>
          </b-col>
        </div>


      </b-row>
        <b-row class="pt-8">

          <div class="col-6">
            <b-col ><label :for="`date`">Sản phẩm</label></b-col>
            <b-col >
              <multiselect
                  v-model="product"
                  :options="options.product"
                  :multiple="true"
                  label="name"
                  track-by="id"
              ></multiselect>
            </b-col>
          </div>
          <div class="col-3">
            <b-col ><label :for="`date`">Từ ngày:</label></b-col>
            <b-col >
              <b-form-input
                  :id="`type-date`"
                  :type="`date`"
                  v-model="dateFrom"
              ></b-form-input>
            </b-col>
          </div>
          <div class="col-3">
            <b-col ><label :for="`date`">Đến ngày:</label></b-col>
            <b-col>
              <b-form-input
                  :id="`type-date`"
                  :type="`date`"
                  v-model="dateTo"
              ></b-form-input>
            </b-col>
          </div>

        </b-row>
      <b-row class="text-right pt-8">

          <b-col class="text-right">
            <b-button-group>
              <b-button type="button" variant="primary" @click="search()">
                <i class="la la-search"></i>
                <span>Tìm kiếm</span>
              </b-button>

              <b-button type="button" variant="success" @click="$bvModal.show(modalId)">
                <i class="la la-file-export"></i>
                <span>Xuất file</span>
              </b-button>
            </b-button-group>
          </b-col>

      </b-row>

    </div>

    <b-card>
      <b-card-body>
        <p v-if="!data">Không có dữ liệu</p>
        <div v-else class="table-responsive text-center">
          <table class="table table-striped table-bordered table-hover">
            <thead>
              <tr>
                <th>STT</th>
                <th>Time</th>
                <th>NCC dịch vụ</th>
                <th>Dịch vụ</th>
                <th>Loại dịch vụ</th>
                <th>Sản phẩm</th>
                <th>Tổng SLGD</th>
                <th>Tổng giá trị GD</th>
                <th>Phí trả</th>
                <th>Phí thu</th>
                <th>Phí hưởng</th>
              </tr>
            </thead>
            <tbody>
              <tr v-bind:key="key" v-for="(item, key) in data " :style="item.service ? '' : 'font-weight: 600'">
                <td>{{ item.service ? ( key + 1) : '' }}</td>
                <td>{{ item.time }}</td>
                <td>{{ item.provider }}</td>
                <td>{{ item.service }}</td>
                <td>{{ item.service_name }}</td>
                <td>{{ item.product }}</td>
                <td>{{ numberFormat(item.count) }}</td>
                <td>{{ numberFormat(item.amount) }}</td>
                <td>{{ numberFormat(item.fee_expense) }}</td>
                <td>{{ numberFormat(item.fee_received) }}</td>
                <td>
                  {{ numberFormat(item.fee_received - item.fee_expense) }}
                </td>
              </tr>
            </tbody>
          </table>
          <div>
            <b-pagination-nav
              v-model="currentPage"
              :link-gen="createLinkPage"
              :number-of-pages="totalPage"
              use-router
              first-number
              last-number
              align="right"
            ></b-pagination-nav>
          </div>
        </div>
      </b-card-body>
    </b-card>

    <alert-dialogue ref="confirmDialogue"></alert-dialogue>
    <export-modal :modal-id="modalId" @exportData="exportData"></export-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Common from "@/core/mixins/common";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import AlertDialogue from "@/view/pages/components/AlertModal.vue";
import ExportModal from "@/view/pages/components/ExportModal.vue";
import CmsRepository from "@/core/repositories/cmsRepository";
import Multiselect from "vue-multiselect";
import Vue from "vue";

Vue.component("multiselect", Multiselect);
const FeeRepository = RepositoryFactory.get("fee");

export default {
  name: "Report",
  components: { ExportModal, AlertDialogue },
  mixins: [Common],
  data() {
    return {
      currentPage: 1,
      totalPage: 1,
      modalId: "export_report_fee",
      data: {},
      listConfig: null,
      provider: null,
      service: null,
      list_service: null,
      product: null,
      dateFrom: this.$route.query.date_from ?? this.getFirstDayOfMonth(),
      dateTo: this.$route.query.date_to ?? this.getCurrentDay(),
      errorMessage: "Vui lòng chọn thời gian",
      options: {
        service: [
            { code: null, name: "Tất cả" },
        ],
        provider: [
          { code: null, name: "Tất cả" },
        ],
        list_service: [],
        product: [],
      }
    };
  },
  methods: {
    search() {
      if (this.currentPage != 1) {
        this.$router.replace({});
        this.currentPage = 1;
        return;
      }
      this.getList()
    },
    getList(numberPage = null) {
      if (this.dateFrom == null) {
        this.notifyAlert("warn", "Vui lòng chọn thời gian");
        return false;
      }
      this.$bus.$emit("show-loading", true);
      if (this.$route.query.page !== "undefined" && numberPage == null) {
        numberPage = this.$route.query.page;
      }

      this.data = {};
      this.errorMessage = null;
      let params = {
        date_from: this.dateFrom,
        date_to: this.dateTo,
        provider: this.service,
        service: this.provider,
        page: this.currentPage ?? 1,
        fee_type: 'FEE_PROVIDER'
      };
      if (this.list_service != null && this.list_service.length != 0) {
        let list = [];
        this.list_service.forEach( (item => {
          list.push(item.id)
        }))
        params.list_service = JSON.stringify(list)
      }

      if (this.product != null && this.product.length != 0) {
        let list = [];
        this.product.forEach( (item => {
          list.push(item.id)
        }))
        params.product = JSON.stringify(list)
      }
      params = this.removeValidateNull(params);
      return FeeRepository.feeDetail(params)
        .then((response) => {
          if (response.status !== 200 || response.data.error_code === 1) {
            return (this.errorMessage = "Có lỗi khi lấy thông tin");
          }
          this.data = response.data.data.data;
          this.totalPage = response.data.data.lastPage;
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          this.$bus.$emit("show-loading", false);
          return (this.errorMessage = "Có lỗi khi lấy thông tin");
        });
    },
    async exportData(description = null) {
      this.$bvModal.hide(this.modalId);
      this.$bus.$emit("show-loading", true);
      this.errorMessage = null;
      let self = this;
      if (this.dateFrom == null) {
        this.notifyAlert("warn", "Vui lòng chọn thời gian");
        return false;
      }

      this.errorMessage = null;
      let params = {
        wallet_type: "FEE_PROVIDER",
        date_from: this.dateFrom,
        date_to: this.dateTo,
        provider: this.service,
        service: this.provider,
        page: this.currentPage,
        export_desc: description ?? "Báo cáo chi tiết NCC dịch vụ",
        fee_type: 'FEE_PROVIDER',
      };
      if (this.list_service != null && this.list_service.length != 0) {
        let list = [];
        this.list_service.forEach( (item => {
          list.push(item.id)
        }))
        params.list_service = JSON.stringify(list)
      }

      if (this.product != null && this.product.length != 0) {
        let list = [];
        this.product.forEach( (item => {
          list.push(item.id)
        }))
        params.product = JSON.stringify(list)
      }

      params = this.removeValidateNull(params);
      return CmsRepository.exportFileReconcileWallet(params)
        .then(async (response) => {
          if (response.status !== 200 || response.data.error_code === 1) {
            self.$bus.$emit("show-loading", false);
            return (self.errorMessage = "Có lỗi khi lấy thông tin");
          }
          let link = window.location.origin + "/#/reconcile/export-data";

          self.$bus.$emit("show-loading", false);
          const cfm = await this.$refs.confirmDialogue.show({
            title: "Download File",
            message:
              "File đang được xử lý, bạn có thể tải xuống sau ít phút <a href='" +
              link +
              "' target='_blank'>TẠI ĐÂY</a>",
            okButton: "Ok",
          });

          if (!cfm) {
            this.$bus.$emit("show-loading", false);
            return (self.errorMessage = "Có lỗi khi lấy thông tin");
          }

          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          this.$bus.$emit("show-loading", false);
          return (self.errorMessage = "Có lỗi khi lấy thông tin");
        });
    },
    async listService(params) {
      this.$bus.$emit("show-loading", true);
      const response = await CmsRepository.listServiceFee(params);
      this.$bus.$emit("show-loading", false);
      return response?.data?.data;
    },
    async listConfigService() {
      let params = {};
      const response = await CmsRepository.listServiceConfigFee(params);
      this.listConfig = response?.data?.data?.RECEIVED;
      this.listConfig.forEach( (item) => {
        if (item.code == "SERVICE_PROVIDER") {
          this.options.service = this.options.service.concat(item.data)
        }
      })
    },
    getProvider() {
      this.options.service.forEach( (item) => {
        if (this.service == item.code) {
          this.options.provider = this.options.provider.concat(item.data)
        }
      })
    }
  },
  watch: {
    "currentPage"() {
      this.getList(this.currentPage);
    },
    "service"() {
      this.getProvider()
    },
    async "provider"() {
      let params = {
        type: "SERVICE",
        service_type: this.provider,
      };
      let data = await this.listService(params)
      data.forEach( (item) => {
        this.options.list_service.push(item)
      })
    },
    async "list_service"() {
      let productIds = [];
      this.list_service.forEach((item) => {
        productIds.push(item.id);
      });
      let params = {
        type: "PRODUCT",
        service_id: JSON.stringify(productIds),
      };

      let data = await this.listService(params)
      data.forEach( (item) => {
        this.options.product.push(item)
      })

    }
  },
  mounted() {
    if (this.dateFrom  != null && this.dateTo != null) {
      this.getList();
    }
    this.listConfigService()
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Báo cáo chi tiết NCC dịch vụ" },
    ]);
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.mx-datepicker {
  width: 100% !important;
}
</style>
